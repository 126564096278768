import {
  collection,
  DocumentReference,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  UpdateData,
  where,
} from 'firebase/firestore/lite';
import { equals } from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFirestore } from '../firebase/app';

export interface UseCollection<T> {
  isLoading: boolean;
  error?: Error;
  data?: T[];
}

export interface UseCollectionOptions<T, F extends (keyof T & string) = never> {
  filter?: {
    field: F;
    value: T[F];
  }
}

export function createUseCollection<T>(path: string) {
  return function useCollection<F extends (keyof T & string) = never>({ filter }: UseCollectionOptions<T, F> = {}): UseCollection<T> {
    const db = useFirestore();

    const [data, setData] = useState<T[]>();
    const [error, setError] = useState<Error>();
    const [isLoading, setIsLoading] = useState(true);

    const filterRef = useRef(filter);

    if (!equals(filter, filterRef.current)) {
      filterRef.current = filter;
    }

    const { current: currentFilter } = filterRef;
    useEffect(
      () => {
        const w = currentFilter ? [where(currentFilter.field, '==', currentFilter.value)] : [];
        const q = query(collection(db, path) as CollectionReference<T>, ...w);
        setError(undefined);
        setIsLoading(true);
        getDocs<T>(q)
          .then(({ docs }) => docs.map(doc => doc.data()))
          .then(setData)
          .catch(setError)
          .then(() => setIsLoading(false));
      },
      [db, currentFilter],
    );

    return {
      data,
      error,
      isLoading,
    };
  };
}

export interface UseItem<T> {
  isLoading: boolean;
  error?: Error;
  data?: T;
}

export function createUseItem<T>(path: string) {
  return function useItem(id: string): UseItem<T> {
    const db = useFirestore();

    const [data, setData] = useState<T>();
    const [error, setError] = useState<Error>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(
      () => {
        setData(undefined);
        getDoc<T>(doc(db, `${path}/${id}`) as DocumentReference<T>)
          .then(doc => doc.data())
          .then(setData)
          .catch(setError)
          .then(() => setIsLoading(false));
      },
      [db, id],
    );

    return {
      data,
      error,
      isLoading,
    };
  };
}

export interface UseSaveItem<T> {
  isLoading: boolean;
  error?: Error;
  result?: UpdateData<T>;
  update(item: UpdateData<T>): void;
}

export function createUseUpdateItem<T>(path: string) {
  return function useItem(id: string): UseSaveItem<T> {
    const db = useFirestore();

    const [error, setError] = useState<Error>();
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<UpdateData<T>>()

    const update = useCallback(
      <U extends UpdateData<T>>(data: U) => {
        setIsLoading(true);
        updateDoc(doc(db, `${path}/${id}`) as DocumentReference<T>, data)
          .then(() => setResult(data))
          .catch(setError)
          .then(() => setIsLoading(false));
      },
      [db, id],
    );

    return {
      update,
      error,
      isLoading,
      result,
    };
  };
}
