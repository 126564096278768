import {
  mdiPencil,
  mdiShield,
  mdiGoogle,
  mdiLock,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useMemo } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { ErrorDisplay } from '../../../components/Error';
import { Loading } from '../../../components/Loading';
import { Table } from '../../../components/Table';
import { User } from '../../../hooks/use-auth';
import { useUsers } from '../../../hooks/use-users';

import style from './index.module.css';

export function UsersManagement() {
  const { path } = useRouteMatch();

  const columns = useMemo(
    (): Column<User>[] => [
      {
        id: 'UserProvider',
        Cell: ({ row }: { row: Row<User> }) => {
          if (row.original.provider === 'google.com') {
            return <Icon path={mdiGoogle} size="1.2rem" title="Google.com" />;
          }
        },
      },
      {
        id: 'UserPhoto',
        Header: '',
        Cell: ({ row }: { row: Row<User> }) => (
          <img
            src={row.original.photoUrl}
            title={row.original.displayName}
            alt={row.original.displayName}
            className={style.Avatar}
          />
        ),
      },
      {
        Header: 'Name',
        accessor: 'displayName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Last Seen',
        Cell: ({ row }: { row: Row<User> }) => {
          return <span>{row.original.lastSeen.toDate().toLocaleString()}</span>;
        },
      },
      {
        id: 'Status',
        Cell: ({ row }: { row: Row<User> }) => {
          return <>
            {row.original.isAdmin && <Icon path={mdiShield} size="1.2rem" title="Admin" />}
            {row.original.isBlocked && <Icon path={mdiLock} size="1.2rem" title="Blocked" />}
          </>;
        },
      },
      {
        id: 'Actions',
        Cell: ({ row }: { row: Row<User> }) => {
          return <>
            <Link to={`${path}/${row.original.uid}`}>
              <Icon path={mdiPencil} size="1.2rem" title="Edit" />
            </Link>
          </>;
        },
      },
    ],
    [path],
  );

  const {
    isLoading,
    error,
    data,
  } = useUsers();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (!data) {
    return null;
  }

  return <>
    <Table<User> columns={columns} data={data} />
  </>;
}
