import { mdiFacebook, mdiInstagram } from '@mdi/js';
import Icon from '@mdi/react';
import { useEffect } from 'react';
import { useAnalytics } from '../../firebase/app';

import style from './index.module.css';

export function Contact(): JSX.Element {
  const { logEvent } = useAnalytics();

  useEffect(
    () => {
      logEvent('show_contact');
    },
    [logEvent],
  );

  return <div className={style.Contact}>
    <p>Follow me and contact me on:</p>
    <ul>
      <li>
        <a target="_blank" href="https://www.instagram.com/shiksha_mantri_art" rel="noreferrer">
          <Icon path={mdiInstagram} size="3rem" title="Instagram" />&nbsp;
          @shiksha_mantri_art
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/Shiksha-studio-1507799342623102" target="_blank" rel="noreferrer">
          <Icon path={mdiFacebook} size="3rem" title="Facebook" />&nbsp;
          Shiksha studio
        </a>
      </li>
    </ul>
  </div>;
}
