import { useCallback, useEffect, useState } from 'react';
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut as firebaseSignOut } from "firebase/auth";
import { useFirebaseAuth, useFirestore } from '../firebase/app';
import { doc, DocumentReference, getDoc, setDoc, Timestamp } from 'firebase/firestore/lite';

const googleAuthProvider = new GoogleAuthProvider();

export interface User {
  displayName: string;
  email: string;
  uid: string;
  photoUrl?: string;
  lastSeen: Timestamp;
  isAdmin?: boolean;
  isBlocked?: boolean;
  provider?: string;
}

export function useAuth() {
  const auth = useFirebaseAuth();
  const db = useFirestore();

  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState<Error>();

  const updateUser = useCallback(
    async <T extends Partial<User> & Pick<User, 'uid'>>(user?: T) => {
      if (user) {
        await setDoc(
          doc(db, 'users', user.uid),
          {
            ...user,
            lastSeen: Timestamp.fromDate(new Date()),
          },
          {
            merge: true,
          },
        );
      }
      return user;
    },
    [db],
  )

  const signInWithGoogle = useCallback(
    () => {
      signInWithPopup(auth, googleAuthProvider)
        .then(({ user }) => {
          return updateUser({
            displayName: user.displayName!,
            email: user.email!,
            photoUrl: user.photoURL ?? undefined,
            uid: user.uid,
            provider: user.providerData[0]?.providerId,
          });
        })
        .catch(setError);
    },
    [auth, updateUser],
  );

  const signOut = useCallback(
    () => {
      firebaseSignOut(auth);
    },
    [auth],
  );

  useEffect(
    () => {
      onAuthStateChanged(auth, user => {
        if (user) {
          setIsLoading(true);
          getDoc(doc(db, `users/${user.uid}`) as DocumentReference<User>)
            .then(snapshot => snapshot.data())
            .then(updateUser)
            .then(setUser)
            .then(() => user.getIdTokenResult())
            .then(({ claims }) => setIsAdmin(!!claims["admin"]))
            .catch(setError)
            .then(() => setIsLoading(false));
        } else {
          setUser(undefined);
          setIsAdmin(false);
          setIsLoading(false);
        }
      });
    },
    [auth, db, updateUser],
  );

  return {
    user,
    error,
    isAdmin,
    isLoading,
    signInWithGoogle,
    signOut,
  };
}
