import { useEffect } from 'react';
import { Redirect, useRouteMatch } from 'react-router';
import { Html } from '../../components/Html';
import { ImageDisplay } from '../../components/ImageDisplay';
import { Loading } from '../../components/Loading';
import { useAnalytics } from '../../firebase/app';
import { useImage } from '../../hooks/use-image';

import style from './index.module.css';

export function Image() {
  const { logEvent } = useAnalytics();

  const { params: { id } } = useRouteMatch<{ id: string }>();

  const {
    isLoading,
    data,
  } = useImage(id);

  useEffect(
    () => {
      if (data) {
        logEvent('show_image', { uid: id });
      }
    },
    [data, logEvent, id],
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    // TODO handle error
    return <Redirect to="/" />;
  }

  return <div className={style.Image}>
    <div className={style.ImageDisplay}>
      <ImageDisplay image={data} />
    </div>
    <div className={style.ImageDetails}>
      <h1>{data.name}</h1>
      <Html html={data.description ?? ''} />
    </div>
  </div>;
}
