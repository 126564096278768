import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { ErrorDisplay } from '../../components/Error';
import { Loading } from '../../components/Loading';
import { ImageDisplay } from '../../components/admin/ImageDisplay';
import { useImage } from '../../hooks/use-image';
import { useUpdateImage } from '../../hooks/use-update-image';

export function ImageManagement() {
  const { params } = useRouteMatch<{ imageId: string }>();

  const {
    data: image,
    isLoading,
    error,
  } = useImage(params.imageId);

  const {
    isLoading: isUpdating,
    error: updateError,
    update,
  } = useUpdateImage(params.imageId);

  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [isPublic, setIsPublic] = useState<boolean>(false);

  useEffect(
    () => {
      if (image) {
        setName(image.name);
        setIsPublic(!!image.isPublic);
        setDescription(image.description ?? '');
      }
    },
    [image],
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (isLoading || !image) {
    return <Loading />;
  }

  return <>
    {updateError && <ErrorDisplay error={updateError} />}
    <ImageDisplay image={image} size="m" /><br />
    <label>
      Name <input type="text" value={name} onChange={e => setName(e.target.value)} />
    </label>
    <br />
    <label>
      Is public <input type="checkbox" checked={isPublic} onChange={e => setIsPublic(e.target.checked)} />
    </label>
    <br />
    <label>
      Description <textarea onChange={e => setDescription(e.target.value)} value={description} />
    </label>
    <br />
    <button
      disabled={isUpdating}
      onClick={() => update({ name, visibility: isPublic ? 'public' : 'private', description })}
    >
      Save
    </button>
    <br />
  </>;
}
