import { Redirect, Route, Switch } from 'react-router';
import style from './App.module.css';
import { DevOnlyRoute } from './components/DevOnlyRoute';
import { Gallery } from './components/Gallery';
import { Header } from './components/Header';
import { About } from './pages/About';
import { Admin } from './pages/admin/Management';
import { Contact } from './pages/Contact';
import { Image } from './pages/Image';

function App() {
  return (
    <div className={style.App}>
      <Header />
      <Switch>
        <Route exact path="/" component={Gallery} />
        <DevOnlyRoute path="/admin" component={Admin} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/:id" component={Image} />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
