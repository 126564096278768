import { useImageFiles } from '../../hooks/use-image-files';
import { Image } from '../../models/image';
import { Loading } from '../Loading';

export interface ImageDisplayProps {
  image: Image;
}

export function ImageDisplay(
  { image }: ImageDisplayProps,
) {
  const { urls, isLoading } = useImageFiles([image], 'xl');

  if (isLoading) {
    return <Loading />;
  }

  if (!urls) {
    console.error('urls should not be undefined');
    // TODO handle error
    return null;
  }

  return <>
    <img src={urls[0]} title={image.name} alt={image.name} />
  </>;
}
