import {
  mdiLoginVariant,
  mdiLogoutVariant,
  mdiFacebook,
  mdiInstagram,
  mdiCog,
  mdiClose,
  mdiMenu,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import style from './index.module.css';
import { DevOnly } from '../DevOnly';

export function Header() {
  const { isAdmin, signInWithGoogle, user, signOut } = useAuth();
  const [visible, setVisible] = useState(false);
  const toggle = () => setVisible(!visible);
  const close = () => setVisible(false);
  return <header className={style.Header}>
    <div>
      <h1>
        Shiksha Studio
      </h1>
      <button title="Menu Toggle" onClick={toggle}>
        <Icon path={visible ? mdiClose : mdiMenu} />
      </button>
    </div>
    <ul className={visible ? 'open' : undefined}>
      <li>
        <Link to="/" onClick={close}>Home</Link>
      </li>
      <li>
        <Link to="/about" onClick={close}>About</Link>
      </li>
      <li>
        <Link to="/contact" onClick={close}>Contact</Link>
      </li>
      <li>
        <a href="https://www.instagram.com/shiksha_mantri_art" target="_blank" rel="noreferrer">
          <Icon path={mdiInstagram} size="1.5rem" title="Instagram" />
        </a>
        <a href="https://www.facebook.com/Shiksha-studio-1507799342623102" target="_blank" rel="noreferrer">
          <Icon path={mdiFacebook} size="1.5rem" title="Facebook" />
        </a>
      </li>
      <DevOnly>
        <li>
          {
            user && <Link to="/profile" onClick={close}>
              <img src={user.photoUrl} alt={user.displayName} />
            </Link>
          }
          {
            !!isAdmin && <Link to="/admin" onClick={close}>
              <Icon path={mdiCog} size="1.2rem" title="Administration" />
            </Link>
          }
          <Link onClick={!user ? signInWithGoogle : signOut} to="#">
            <Icon
              path={!user ? mdiLoginVariant : mdiLogoutVariant}
              size="1.2rem"
              title={!user ? 'Sign In' : 'Sign Out'} />
          </Link>
        </li>
      </DevOnly>
    </ul>
  </header>
}
