import { ref, uploadBytesResumable } from "firebase/storage";
import { useState } from 'react';
import { useFirebaseStorage } from '../firebase/app';

export function useUploadImage(file: File) {
  const storage = useFirebaseStorage();

  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const upload = () => {
    setIsLoading(true);
    setError(undefined);
    setProgress(0);
    const storageRef = ref(storage, `upload/${file.name}`);
    const task = uploadBytesResumable(storageRef, file);

    task.on(
      'state_changed',
      snapshot => {
        setProgress(snapshot.bytesTransferred / snapshot.totalBytes);
      },
      e => {
        setError(e);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
        setProgress(1);
      },
    )
  };

  return {
    error,
    isLoading,
    progress,
    upload,
  }
}
