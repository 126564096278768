import style from './index.module.css';

export interface ItemProps {
  description: string;
  title: string;
  id: string;
}

export function CustomHoverRenderer(
  {
    title,
    description,
  }: ItemProps,
): JSX.Element {
  const div = document.createElement('div');
  div.innerHTML = description || '';
  let descriptionText = div.textContent ?? '';
  const end = descriptionText.length > 64 ? 64 + descriptionText.slice(64).search(/\s/) : descriptionText.length;
  descriptionText = descriptionText.length === end ? descriptionText : `${descriptionText.slice(0, end)}...`;
  return <div className={style.Hover}>
    <h2>{title}</h2>
    <h3>{descriptionText}</h3>
  </div>;
}
