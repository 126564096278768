export interface ErrorDisplayProps {
  error: Error;
}

export function ErrorDisplay({ error }: ErrorDisplayProps) {
  return <>
    <h4>{error.name}</h4>
    <p>{error.message}</p>
  </>;
}
