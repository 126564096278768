import { ProGallery } from 'pro-gallery';
import { useMemo } from 'react';
import { useImageFiles } from '../../hooks/use-image-files';
import { useImages } from '../../hooks/use-images';

import 'pro-gallery/dist/statics/main.css';
import { } from './index.module.css';
import { CustomHoverRenderer } from './CustomHoverRenderer';

export interface GalleryProps {
  scrollingElement?: HTMLElement | (Window & typeof globalThis);
}

export function Gallery(
  {
    scrollingElement = window,
  }: GalleryProps,
) {

  const {
    data,
  } = useImages({
    filter: {
      field: 'visibility',
      value: 'public',
    }
  });

  const images = useMemo(
    () => data ?? [],
    [data],
  );

  const { urls } = useImageFiles(images, "l");

  if (!urls) {
    return null;
  }

  const items = urls.map(
    (url, i) => {
      const image = images[i];
      return {
        directLink: {
          url: `/${image.uid}`,
          target: '_self',
        },
        itemId: image.uid,
        mediaUrl: url,
        metadata: {
          type: 'image',
          title: image.name,
          description: image.description,
          height: image.files['l'].height,
          width: image.files['l'].width,
          focalPoint: [0, 0],
          link: {
            url: '/',
            target: '_self',
          }
        },
      };
    },
  );

  const options = {
    galleryLayout: -1,
    isVertical: true,
    groupSize: 1,
    galleryMargin: 100,
    imageMargin: 50,
    fixedColumns: 3,
    gridStyle: 1,
    itemClick: 'link',

  };

  // The size of the gallery container. The images will fit themselves in it
  const container = {
    width: 0.8 * window.innerWidth,
  };

  return <>
    <ProGallery
      items={items}
      options={options}
      container={container}
      scrollingElement={scrollingElement}
      customHoverRenderer={CustomHoverRenderer}
    />
  </>;
}
