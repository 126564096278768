import { getDownloadURL, ref } from 'firebase/storage';
import { equals } from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFirebaseStorage } from '../firebase/app';
import { Image } from '../models/image';

export function useImageFiles(images: Image[], size: keyof Image['files']) {
  const storage = useFirebaseStorage();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [urls, setUrls] = useState<string[]>();

  const imagesRef = useRef(images);

  if (!equals(images, imagesRef.current)) {
    imagesRef.current = images;
  }

  const { current } = imagesRef;
  const links = useMemo(
    () => current.map(image => image.files[size].link),
    [current, size],
  );

  useEffect(
    () => {
      setError(undefined);
      setIsLoading(true);
      Promise.all(links.map(link => getDownloadURL(ref(storage, link))))
        .then(setUrls)
        .catch(setError)
        .then(() => setIsLoading(false));;
    },
    [storage, links],
  );

  return {
    error,
    isLoading,
    urls,
  };
}
