import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAnalytics } from '../../firebase/app';
import Picture from './picture.jpg';

import style from './index.module.css';

export function About(): JSX.Element {
  const { logEvent } = useAnalytics();

  useEffect(
    () => {
      logEvent('show_about');
    },
    [logEvent],
  );

  return <div className={style.About}>
    <img src={Picture} alt="Me" />
    <p>Hi, I am Shiksha Mantri Mottet. Welcome to my website!</p>
    <p>
      I was born in a small town in India but was very fortunate to have parents who encouraged creativity and curiosity in everything we did.&nbsp;
      This led to some funky science experiments at home, a great time at uni (univ. Of Oxford PhD, IIT Bombay undergrad) and some very experimental but completely original art.
    </p>
    <p>
      I would love to know what you think of the art and the <a href="https://www.youtube.com/channel/UCy7SvpIGLl022bPrzGPEo-g" target="_blank" rel="noreferrer">science videos</a>!&nbsp;
      <Link to="/contact">Write to me!</Link>
    </p>
  </div>;
}
