import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import { useAuth } from '../../../hooks/use-auth';
import { GalleryManagement } from '../GalleryManagement';
import { ImageManagement } from '../ImageManagement';
import { Upload } from '../Upload';
import { UserManagement } from '../User';
import { UsersManagement } from '../Users';

import style from './index.module.css';

export function Admin() {
  const { path } = useRouteMatch();


  const { isLoading, isAdmin } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && !isAdmin) {
    return <Redirect to="/" />;
  }

  return <div className={style.Management}>
    <div className={style.Menu}>
      <ul>
        <li>
          <Link to={`${path}/gallery`}>
            Gallery
          </Link>
        </li>
        <li>
          <Link to={`${path}/users`}>
            Users
          </Link>
        </li>
      </ul>
    </div>
    <div>
      <Switch>
        <Route exact path={`${path}/gallery`} component={GalleryManagement} />
        <Route exact path={`${path}/users`} component={UsersManagement} />
        <Route exact path={`${path}/gallery/upload`} component={Upload} />
        <Route path={`${path}/gallery/:imageId`} component={ImageManagement} />
        <Route path={`${path}/users/:id`} component={UserManagement} />
      </Switch>
    </div>
  </div>;
}
