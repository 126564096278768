import { useEffect, useRef } from 'react';

export interface HtmlProps {
  html: string;
}

export function Html({ html }: HtmlProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(
    () => {
      if (ref.current) {
        ref.current.innerHTML = html;
      }
    },
    [html, ref],
  );
  return <div ref={ref} />;
}
