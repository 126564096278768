import { useState } from 'react';
import { ImageUpload } from '../../components/ImageUpload';

export function Upload(): JSX.Element {

  const [images, setImages] = useState<File[]>();

  return <div>
    {images && images.length && (
      <div>
        {[...images].map(f => <ImageUpload
          key={f.name}
          file={f}
          onRemove={() => setImages(images.filter(({ name }) => name !== f.name))}
        />)}
      </div>
    )}
    <label>
      Click to select some files...
      <input
        style={{ display: "none" }}
        type="file"
        multiple={true}
        onChange={e => {
          const { files } = e.target;
          if (!files) {
            setImages(undefined);
          } else {
            setImages([...files]);
          }
        }}
      />
    </label>
  </div>
}
