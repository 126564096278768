import { mdiLock, mdiLockOpen, mdiShield, mdiShieldOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useEffect, useState } from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import { ErrorDisplay } from '../../../components/Error';
import { Loading } from '../../../components/Loading';
import { useUpdateUser } from '../../../hooks/use-update-user';
import { useUser } from '../../../hooks/use-user';

import style from './index.module.css';

export function UserManagement() {
  const { params, path } = useRouteMatch<{ id: string; }>();
  const { push } = useHistory();

  const { isLoading, error, data } = useUser(params.id);
  const { isLoading: isUpdating, update, result } = useUpdateUser(params.id);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(
    () => {
      if (data) {
        setIsAdmin(!!data.isAdmin);
        setIsBlocked(!!data.isBlocked);
      }
    },
    [data],
  )

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (!data || result) {
    return <Redirect to={path.replace('/:id', '')} />;
  }

  return <>
    <img src={data.photoUrl} alt="Profile" className={style.Avatar} />
    <h4>{data.displayName}</h4>
    <h5 className={style.Email}>{data.email}</h5>
    <p>Last seen: {data.lastSeen.toDate().toLocaleString()}</p>
    <div className={style.Toggle}>
      <label onClick={() => setIsAdmin(!isAdmin)}>
        <span>Admin</span>
        {isAdmin ?
          <Icon path={mdiShield} size="1.5rem" title="Revoke Admin" /> :
          <Icon path={mdiShieldOutline} size="1.5rem" title="Grant Admin" />}
      </label>
      <i>Admin users can update the site content and manage the comments.</i>
    </div>
    <div className={style.Toggle}>
      <label onClick={() => setIsBlocked(!isBlocked)}>
        <span>Blocked</span>
        {isBlocked ?
          <Icon path={mdiLock} size="1.5rem" title="Unblock" /> :
          <Icon path={mdiLockOpen} size="1.5rem" title="Block" />}
      </label>
      <i>Blocked users are not able to post comments or react to images.</i>
    </div>
    <div className={style.Buttons}>
      <button onClick={() => push(path.replace('/:id', ''))} className={style.Button}>Cancel</button>
      <button disabled={isUpdating} onClick={() => update({ isBlocked, isAdmin })} className={style.Button}>Save</button>
    </div>
  </>;
}
