export const config = {
  firebase: {
    apiKey: "AIzaSyCDLBFIg6oogk7uT3yv6--SDX-6kXpdoNQ",
    authDomain: "portfolio-35de3.firebaseapp.com",
    databaseURL: "https://portfolio-35de3.firebaseio.com",
    projectId: "portfolio-35de3",
    storageBucket: "portfolio-35de3.appspot.com",
    messagingSenderId: "485156182632",
    appId: "1:485156182632:web:e5c437a8b4279a452939dd",
    measurementId: "G-EE8WGHZRV4",
  }
};
