import { useImageFiles } from '../../hooks/use-image-files';
import { Img } from 'react-image';
import { Loading } from '../Loading';
import { Image } from '../../models/image';

export interface ImageItemProps {
  image: Image;
  size?: keyof Image['files'];
}

export function ImageDisplay({ image, size = 'xs' }: ImageItemProps) {
  const {
    isLoading,
    urls,
  } = useImageFiles([image], size);

  if (isLoading) {
    return <Loading />;
  }

  if (!urls) {
    return null;
  }

  return <Img src={urls[0]} loader={<Loading />} title={image.name} alt={image.name} />;
}
