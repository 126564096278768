import { mdiTrashCan, mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import fileSize from 'filesize';
import { useEffect, useState } from 'react';
import { useUploadImage } from '../../hooks/use-upload-image';
import { Loading } from '../Loading';

import style from './index.module.css';

export interface FileUploadProps {
  file: File;
  className?: string;
  onRemove?: () => void;
}

export function ImageUpload({ file, className, onRemove }: FileUploadProps): JSX.Element {
  const { isLoading: isUploading, upload, progress } = useUploadImage(file);
  const [image, setImage] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = e => {
        setImage(e.target?.result as string);
        setIsLoading(false);
      }
      reader.readAsDataURL(file);
    },
    [file],
  );

  if (isLoading) {
    return <Loading />;
  }

  return <div className={`${style.ImageUpload} ${className ?? ''}`}>
    <img src={image} alt={file.name} />
    <p className={style.Name}>{file.name}</p>
    <p className={style.Size}>{fileSize(file.size)}</p>
    {!isUploading && <button onClick={upload}>
      <Icon path={mdiUpload} size="1.2rem" title="Upload" />
    </button>}
    {!isUploading && <button onClick={onRemove}>
      <Icon path={mdiTrashCan} size="1.2rem" title="Delete" />
    </button>}

    {isUploading && <p>{Math.round(progress * 10000) / 100}%</p>}
  </div>
}
