export interface DevOnlyProps {
  children: JSX.Element;
}

export function DevOnly({ children }: DevOnlyProps): JSX.Element | null {
  if (process.env.NODE_ENV === 'production') {
    return null;
  }
  return children;
}
