import { initializeApp, FirebaseOptions } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { getAnalytics, Analytics, logEvent } from 'firebase/analytics';
import { Firestore, getFirestore } from 'firebase/firestore/lite';
import { createContext, useCallback, useContext, useMemo } from 'react';

export interface FirebaseInterface {
  db: Firestore;
  storage: FirebaseStorage;
  auth: Auth;
  analytics: Analytics;
}

const FirebaseAppContext = createContext<FirebaseInterface | null>(null);

export interface FirebaseAppProps {
  children: JSX.Element;
  options: FirebaseOptions;
}

export function FirebaseApp(
  {
    children,
    options,
  }: FirebaseAppProps,
) {
  const app = useMemo<FirebaseInterface>(
    () => {
      const app = initializeApp(options);

      return {
        db: getFirestore(app),
        storage: getStorage(app),
        auth: getAuth(app),
        analytics: getAnalytics(app),
      };
    },
    [options],
  );

  return <FirebaseAppContext.Provider value={app}>
    {children}
  </FirebaseAppContext.Provider>;
}

export function useFirestore() {
  const firebaseInterface = useContext(FirebaseAppContext);

  if (!firebaseInterface) {
    throw new Error('A FirebaseApp should be initialized to use useFirestore.');
  }

  return firebaseInterface.db;
}

export function useFirebaseStorage() {
  const firebaseInterface = useContext(FirebaseAppContext);

  if (!firebaseInterface) {
    throw new Error('A FirebaseApp should be initialized to use useFirestore.');
  }

  return firebaseInterface.storage;
}

export function useFirebaseAuth() {
  const firebaseInterface = useContext(FirebaseAppContext);

  if (!firebaseInterface) {
    throw new Error('A FirebaseApp should be initialized to use useFirestore.');
  }

  return firebaseInterface.auth;
}

export function useAnalytics() {
  const firebaseInterface = useContext(FirebaseAppContext);

  if (!firebaseInterface) {
    throw new Error('A FirebaseApp should be initialized to use useFirestore.');
  }

  const logEventLocal = useCallback(
    (eventName: string, eventParams: Record<string, unknown> = {}) => logEvent(
      firebaseInterface.analytics,
      eventName,
      eventParams,
    ),
    [firebaseInterface],
  );

  return {
    logEvent: logEventLocal,
  };
}
