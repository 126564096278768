import { Link, useRouteMatch } from 'react-router-dom';
import { ImageDisplay } from '../../components/admin/ImageDisplay';
import { Loading } from '../../components/Loading';
import { useImages } from '../../hooks/use-images';

export function GalleryManagement() {
  const { path } = useRouteMatch();
  const { data, isLoading } = useImages();

  if (isLoading) {
    return <Loading />;
  }

  return <>
    {data?.map(image => <p key={image.uid}>
      <Link to={`${path}/${image.uid}`}>
        <ImageDisplay image={image} size="s" />
      </Link>
    </p>)}
  </>;
}
